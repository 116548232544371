<template>
  <div class="bg-gray-50">
    <div class="container mx-auto max-w-screen-xl p-6 pb-0">
    <t-alert class="inline-block" show :dismissible="false" v-html-safe="message">
      
    </t-alert>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      require: true
    }
  }
}
</script>
