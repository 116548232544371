<template>
  <div class="home bg-gray-50">
    <Header :checked="false" />

    <AppList :cat="cat" />

    <div class="bg-gray-100 border-t border-gray-300">
      <DocList :cat="cat" />
    </div>
  </div>
</template>

<script>
// Components
import Header from "@/components/Layout/Header";

import AppList from "@/components/Apps/List";
import DocList from "@/components/Docs/List";

export default {
  name: "Dashboard",
  data() {
    return {
      cat: "student",
    };
  },
  components: {
    Header,
    AppList,
    DocList,
  },

};
</script>
