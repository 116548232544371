<template>
  <div class="doc-list container mx-auto py-2 pb-8 max-w-screen-xl px-6">
    <h2 class="text-3xl mt-6 mb-8">Anleitungen</h2>
    <autocomplete
      :search="search"
      placeholder="Anleitungen durchsuchen"
      aria-label="Anleitungen durchsuchen"
      :debounceTime="10"
    ></autocomplete>
    <div class="docs mt-8">
      <div v-if="docs[0]" :class="DocsLoaded ? 'loaded' : ''">
        <div class="doclist">
          <DocCard
            v-for="doc in docs"
            :key="doc.id"
            :doc="doc"
            @openModal="openViewerModal"
          />
        </div>
        <div class="mt-4">
          <pagination
            v-model="page"
            :records="total"
            :per-page="perPage"
            @paginate="pagination"
            :options="options"
          />
        </div>
      </div>
      <p v-else>Aktuell sind keine Anleitungen verfügbar</p>
    </div>
    <Viewer
      v-if="showViewerModal"
      :DocID="DocID"
      :showModal="showViewerModal"
      @closeModal="closeViewerModal"
    />
  </div>
</template>
<script>
// Mixins
import getDocs from "../../mixins/docs/getDocs";
import docListFunctions from "../../mixins/docs/docListFunctions";

// PaginationTheme
import PaginationTheme from "@/components/Pagination/Theme";

export default {
  mixins: [getDocs, docListFunctions],
  props: {
    cat: {
      type: String,
    },
  },

  data() {
    return {
      docs: {},
      total: 0,
      page: 1,
      perPage: 5,
      options: {
        template: PaginationTheme,
        texts: {
          count:
            "{from} bis {to} von {count} Anleitungen|{count} Anleitungen| Eine Anleitung",
        },
        edgeNavigation: false,
      },
    };
  },
  created() {
    this.getDocs(this.cat);
    if (this.$route.query.doc) {
      this.DocID = parseInt(this.$route.query.doc);
      this.openViewerModal({ id: this.DocID });
    }
  },
};
</script>
