<template>
  <footer class="bg-white">
    <div
      class="container mx-auto py-10 flex justify-between max-w-screen-xl px-6"
    >
      <a :href="url" class="flex items-top cursor-pointer">
        <UserIcon size="1.2x" class="mr-1" /> Admin Login
      </a>
      <a href="https://nativv.ch" class="flex items-center text-sm"
        ><Smile class="smile mr-2" />Enwickelt von nativv Digital</a
      >
    </div>
  </footer>
</template>
<script>
import Smile from "@/assets/svg/smile.svg?inline";

import { UserIcon, LogOutIcon } from "vue-feather-icons";

export default {
  data() {
    return {
      url: process.env.VUE_APP_BACKEND_URL,
    };
  },
  components: {
    Smile,
    UserIcon,
    LogOutIcon,
  },
  methods: {
    async logout() {
      this.$store.dispatch("logout");
      this.$notify({
        group: "global",
        type: "success",
        title: "Erfolgreich abgemeldet!",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.smile {
  height: 1rem;
  width: 1rem;
  margin-bottom: -0.5px;
}
</style>
