<template>
  <div class="categories flex mb-8">
    <t-button
      variant="secondary"
      @click="$emit('reset')"
      :class="this.filter === 'all' ? 'border-blue-500 bg-blue-50' : ''"
      >Alle</t-button
    >
    <t-button
      variant="secondary"
      class="ml-2"
      :class="this.filter === 'unterstufe' ? 'border-blue-500 bg-blue-50' : ''"
      @click="$emit('filter', 'unterstufe')"
      >KG - 2. Klasse</t-button
    >
    <t-button
      variant="secondary"
      class="ml-2"
      :class="this.filter === 'oberstufe' ? 'border-blue-500 bg-blue-50' : ''"
      @click="$emit('filter', 'oberstufe')"
      >3. - 6. Klasse</t-button
    >
  </div>
</template>
<script>
export default {
  props: ['filter'],
}
</script>
