<template>
  <div class="app-list container mx-auto pb-10 max-w-screen-xl px-6 pt-10">
    <section class="app-list-container">
      <h2 v-if="cat === 'student'" class="text-2xl md:text-3xl mb-6">
        Apps für SchülerInnen
      </h2>
      <h2 v-else class="text-2xl md:text-3xl mb-6">Apps für Lehrpersonen</h2>

      <div class="toolbar flex" v-if="cat === 'student'">
        <AppFilter
          :filter="filter"
          @filter="filterStufe"
          @reset="resetFilter"
        />
      </div>
      <transition name="fade">
        <div class="loader py-20" v-if="loading">
          <div class="spinner my-20">
            <div class="cube1"></div>
            <div class="cube2"></div>
          </div>
        </div>
      </transition>
      <div v-if="!loading">
        <div class="apps" :class="loadedAnmiation ? 'loaded' : ''">
          <AppCard
            v-for="app in apps"
            :key="app.id"
            :app="app"
            @appLoading="appLoading"
          />
        </div>
      </div>
    </section>
    <AppLoading v-if="appOpening" />
  </div>
</template>
<script>
//Mixins
import getApps from "../../mixins/apps/getApps";
import appListFunctions from "../../mixins/apps/appListFunctions";

export default {
  mixins: [getApps, appListFunctions],
  components: {
    AppLoading: () => import("./Loader"),
  },

  data() {
    return {
      appOpening: false,
    };
  },
  created() {
    this.getApps(this.cat);
  },
};
</script>
