<template>
  <t-card
    class="app card cursor-pointer transition hover:shadow-xl relative"
  >
    <div @click="openApp(app.url)">
      <div
        class="toolbar absolute left-0 top-0 flex justify-end w-full z-10"
        v-if="editable"
      >
        <div
          class="
            bg-white
            rounded-full
            p-2
            shadow-md
            flex
            justify-center
            items-center
            border-gray-400 border
          "
          @click="$emit('delete', app)"
        >
          <TrashIcon />
        </div>
        <div
          class="
            bg-white
            rounded-full
            p2
            shadow-md
            flex
            justify-center
            items-center
            border-gray-400 border
          "
          @click="$emit('openModal', app)"
        >
          <EditIcon />
        </div>
      </div>

      <div
        class="
          bg-blue-100
          border-b border-gray-300
        "
      >

        <img :src="`${files}/${app.icon}`" :alt="app.name" />
      </div>
      <div class="p-4 pb-3 text-center sm:text-left">
        <h3 class="text-xl mb-1">{{ app.name }}</h3>
        <p class="text-sm hidden sm:block">{{ app.description }}</p>
      </div>
    </div>
    <template v-slot:footer>
      <div class="justify-end flex">
        <t-button variant="secondary" type="button" @click="openApp(app.url)">
          App öffnen
        </t-button>
      </div>
    </template>
  </t-card>
</template>
<script>
// Icons
import { TrashIcon, EditIcon } from "vue-feather-icons";

export default {
  props: {
    app: {
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cloudinary: process.env.VUE_APP_CLOUDINARY_BASE,
      files: `${process.env.VUE_APP_BACKEND_URL}/assets`,
    };
  },
  components: {
    TrashIcon,
    EditIcon,
  },
  methods: {
    openApp(url) {
      if (!this.editable) {
        window.open(url, "_blank");
      }
      this.$emit("appLoading");
    },
  },
};
</script>
<style lang="scss" scoped>
// App Icon

.app-icon {
  border-bottom: 1px solid #eee;
}
</style>
