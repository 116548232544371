<template>
  <div
    class="header text-white z-10 flex items-end justify-center overflow-hidden relative h-48 md:h-64 bg-blue-100"
    v-lazy-container="{ selector: 'img' }"
  >
    <div
      class="container max-w-screen-xl flex items-center wrapper mb-4 md:mb-6 px-6"
    >
      <div class="relative z-10">
        <h1
          class="title lg:text-5xl md:text-5xl text-3xl font-bold leading-none"
        >
          {{ greetingByTime }}
        </h1>
      </div>
    </div>

    <img
      class="w-full h-full absolute inset-0 z-0"
      :src="`${cloudinary}/f_auto,w_800,h_600,c_fill/liestal-background/${season}/${image}.jpg`"
      :srcset="`${cloudinary}/f_auto,w_2880,h_1024,c_fill/liestal-background/${season}/${image}.jpg 2880w, ${cloudinary}/f_auto,w_1440,h_512,c_fill/liestal-background/${season}/${image}.jpg 1440w, ${cloudinary}/f_auto,w_800,h_600,c_fill/liestal-background/${season}/${image}.jpg 800w, ${cloudinary}/f_auto,w_600,h_400,c_fill/liestal-background/${season}/${image}.jpg 600w`"
    />
  </div>
</template>

<script>

export default {
  data() {
    return {
      season: "",
      image: Math.floor(Math.random() * 10) + 1,
      cloudinary: process.env.VUE_APP_CLOUDINARY_BASE,
    };
  },
  computed: {
    greetingByTime: () => {
      let date = new Date();
      let time = date.getHours();
      if (time > 4 && time < 11) {
        return "Guten Morgen";
      }
      if (time == 11) {
        return "Bald ist Mittag";
      }
      if (time == 12) {
        return "En Guete";
      }
      if (12 < time && time < 17) {
        return "Guten Nachmittag";
      }
      if (17 <= time && time <= 21) {
        return "Guten Abend";
      }
      if ((21 < time) | (time <= 4)) {
        return "Gute Nacht";
      }
    },
  },

  created() {
    this.getBackground();
  },
  methods: {
    getBackground() {
      const month = new Date().getMonth();
      const currentSeasonIndex = Math.floor(((month + 1) / 12) * 4) % 4;
      const currentSeason = ["winter", "spring", "summer", "fall"][
        currentSeasonIndex
      ];
      this.season = currentSeason
      console.log(new Date())
    },
  },
};
</script>
<style lang="scss">
// Header

.header {
  text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.14);
}

.header:before {
  content: "";
  height: 120px;
  width: 100%;
  z-index: 2;
  position: absolute;
  bottom: 0;
  background-position: center center;
  background: linear-gradient(0, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%);
}

// Img

.header img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  max-width: none;
  /*   visibility: hidden;
  animation: fade-in 300ms ease 100ms forwards; */
}

@keyframes fade-in {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
</style>
