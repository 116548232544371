<template>
  <nav
    class="w-full transition duration-500 ease-in-out absolute top-0 left-0 z-10"
  >
    <div
      class="relative px-6 py-4 z-10 max-w-screen-xl h-full mx-auto container lg:shadow-none lg:bg-transparent flex items-center justify-between"
    >
      <div class="flex items-center bg-white px-4 h-12 px-4 rounded-2xl cursor-pointer shadow-md" @click="$router.push('/')">
        <LiestalLogo class="logo" />

        <h1 class="ml-3 font-normal text-base md:text-xl hidden md:block">
          Schule Liestal Dashboard
        </h1>
      </div>
      <div class="flex items-center bg-white h-12 px-4 shadow-sm rounded-2xl md:text-black">
        <span>SUS</span>
        <t-toggle
          @click="modeSwitch(checked)"
          class="mx-2 bg-gray-200"
          :checked="checked"
        />
        <span>LP</span>
      </div>
    </div>
  </nav>
</template>
<script>
import LiestalLogo from '@/assets/svg/liestal.svg'
export default {
  components: {
    LiestalLogo,
  },
  props: ['checked'],
  methods: {
    modeSwitch(teacher) {
      if (!teacher) {
        setTimeout(() => this.$router.push('/lp'), 120)
      } else {
        setTimeout(() => this.$router.push('/'), 120)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
// Logo

.logo {
  height: 30px;
}
</style>
