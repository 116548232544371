<template>
  <t-card
    class="
      doc
      card
      cursor-pointer
      transition
      hover:shadow-xl
      border border-color-gray-200
      relative
    "
  >
    <div @click="$emit('openModal', doc)">

      <div>
        <img
          v-if="doc.icon"
          :src="`${files}/${doc.icon}`"
          :srcset="`${files}/${doc.icon}`"
          :alt="doc.titel"
        />
      </div>
      <div class="p-4 text-center sm:text-left">
        <h3 class="text-xl mb-1">{{ doc.titel }}</h3>
        <p class="text-sm hidden sm:block">{{ doc.auszug }}</p>
      </div>
    </div>
    <template v-slot:footer>
      <div class="flex justify-end">
        <t-button
          variant="secondary"
          type="button"
          @click="$emit('openModal', doc)"
        >
          Anleitung öffnen
        </t-button>
      </div>
    </template>
  </t-card>
</template>
<script>
// Icons
import { TrashIcon, EditIcon } from "vue-feather-icons";

export default {
  props: {
    doc: {
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: `${process.env.VUE_APP_BACKEND_URL}/assets`,

    }
  },
  components: {
    TrashIcon,
    EditIcon,
  },
};
</script>
<style lang="scss" scoped></style>
