export default {
  methods: {
    async getApps(category) {
      const apps = await this.$directus.items('Apps').readMany({ filter: { visible: true, [category]: true } });
      this.setApps(apps)
    },
    setApps(apps) {
      this.allApps = apps.data
      this.apps = this.allApps
      this.loadedAnmiation = true


      setTimeout(() => (this.loadedAnmiation = false), 450)

      const filter = localStorage.getItem('filter')
      if (filter && filter != 'all' && this.$route.path != '/lp') {
        this.filterStufe(filter)
      }

    }
  },

}