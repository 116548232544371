<template>
  <div class="VuePagination" :class="props.theme.wrapper">
    <nav
      :class="props.theme.nav"
      class="flex justify-center items-center flex-wrap"
    >
      <div class="w-full flex justify-center">
        <ul
          v-show="props.showPagination"
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px my-4"
        >
          <li
            class="
              bg-white
              border-gray-300
              text-gray-500
              hover:bg-gray-50
              relative
              inline-flex
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
              cursor-pointer
            "
            :class="props.theme.prev"
            @click="props.setPrevPage"
          >
            <a v-bind="{ ...props.aProps, ...props.prevProps }">{{
              props.texts.prevPage
            }}</a>
          </li>

          <li
            v-for="page in props.pages"
            :key="page"
            class="
              bg-white
              border-gray-300
              text-gray-500
              hover:bg-gray-50
              relative
              inline-flex
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
              cursor-pointer
            "
            :class="props.pageClasses(page)"
            v-on="props.pageEvents(page)"
          >
            <a v-bind="props.aProps" :class="props.theme.link">{{ page }}</a>
          </li>

          <li
            class="
              bg-white
              border-gray-300
              text-gray-500
              hover:bg-gray-50
              relative
              inline-flex
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
              cursor-pointer
            "
            :class="props.theme.next"
            @click="props.setNextPage"
          >
            <a v-bind="{ ...props.aProps, ...props.nextProps }"
              >Nächste Seite</a
            >
          </li>
        </ul>
      </div>
      <p v-show="props.hasRecords" :class="props.theme.count">
        {{ props.count }}
      </p>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["props"],
};
</script>
<style lang="scss" scoped>
.active {
  @apply bg-gray-300;
}

.active:hover {
  @apply bg-gray-400;
}

.disabled {
    @apply opacity-30
}
</style>