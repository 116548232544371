<template>
  <div id="app">
    <Greeting />
    <notifications group="global" position="bottom right" class="global" />
    <MessageInfo :message="message.mitteilung" />
    <router-view />
    <Footer />
  </div>
</template>
<script>
// Style
import "@/assets/scss/global.scss";

// Components
import Greeting from "@/components/Layout/Greeting";
import Footer from "@/components/Layout/Footer";
import BugReport from "@/components/Beta/BugReport";
import MessageInfo from "@/components/Message/Info";

export default {
  data() {
    return {
      checked: false,
      message: { mitteilung: null, id: null },
    };
  },
  watch: {
    $route: function () {
      if (this.$route.path === "/lp") {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
  },
  components: {
    Greeting,
    Footer,
    BugReport,
    MessageInfo,
  },
  metaInfo: {
    title: "Schule Liestal Dashboard",
  },
  mounted() {
    this.getMessage();
  },
  methods: {
    async getMessage() {
      const message = await this.$directus.singleton("Mitteilungen").read();
      this.message = message;
    },
  },
};
</script>
