// Components
import DocCard from "@/components/Docs/Card";
import Pagination from "vue-pagination-2";

// Style
import "@trevoreyre/autocomplete-vue/dist/style.css";

export default {
  components: {
    DocCard,
    Pagination,
    Autocomplete: () => import("@trevoreyre/autocomplete-vue"),
    Viewer: () => import("@/components/Docs/Viewer"),
  },
  data() {
    return {
      docs: [],
      DocsLoaded: false,
      showViewerModal: false,
      DocID: null,
      currentDocData: {},
    };
  },
  methods: {
    setDocs(docs) {
      this.docs = docs.data;
      this.DocsLoaded = true;
    },
    setTot(docs) {
      this.total = docs.data.length
    },
    openViewerModal(payload) {
      this.DocID = payload.id;
      this.showViewerModal = true;
    },
    pagination() {
      +this.page;
      this.getDocs(this.cat, "", this.page);
    },
    closeViewerModal() {
      this.currentDocData = {};
      this.showViewerModal = false;
      this.$router.replace({ query: null });
    },
    async search(input = "") {
      const docs = await this.$directus.items("Docs").readMany({
        filter: { [this.cat]: true, status: "published" },
        search: input,
        limit: this.perPage,

        meta: "total_count",
      });

      this.setDocs(docs);
      this.setTot(docs);
      return [];
    },
  },
};
