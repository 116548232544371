// Components
import AppCard from '@/components/Apps/Card'
import AppFilter from '@/components/Apps/Filter'

export default {
  props: {
    cat: {
      type: String,
    },
  },
  components: {
    AppCard,
    AppFilter,
  },
  data() {
    return {
      allApps: [],
      apps: [],
      filter: 'all',
      loading: false,
      loadedAnmiation: false,
    }
  },
  methods: {
    appLoading() {
      this.appOpening = true
      setTimeout(() => {
        this.appOpening = false
      }, 1500)
    },
    filterStufe(stufe) {
      this.apps = this.allApps.filter((app) => app[stufe])
      localStorage.setItem('filter', stufe)
      this.filter = stufe
    },
    resetFilter() {
      this.apps = this.allApps
      localStorage.setItem('filter', 'all')
      this.filter = 'all'
    },
  },
}
